import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import ComingSoon from "./pages/ComingSoon";

import "./styling/App.css";
import "./styling/Pages.css";
import "./styling/Components.css";
import "./styling/fonts.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFCec4HqHvm56sPhRWvxA4t9OAeeJOJE4",
  authDomain: "post-acute-spalding.firebaseapp.com",
  projectId: "post-acute-spalding",
  storageBucket: "post-acute-spalding.appspot.com",
  messagingSenderId: "797271746679",
  appId: "1:797271746679:web:e3bda64db9792f4d3afe72",
  measurementId: "G-0JQZ8YHF69",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <HelmetProvider>
      <div className="app">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/Home" element={<ComingSoon />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
};

export default App;
